import { mailMessages } from '../../common/commonMessages';
import logo from '../assets/logo-rww.svg';
import colors from './RWW.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.RWW,
  domain: 'rww.de',
  name: 'RWW Rheinisch-Westfälische Wasserwerksgesellschaft mbH',
  contactEmail: 'smexnet@rww.de',
  address: {
    street: 'Am Schloß Broich',
    houseNumber: '1-3',
    zipCode: '45479',
    city: 'Mülheim an der Ruhr',
  },
  districtCourt: 'Duisburg',
  commercialRegisterNumber: 'HRB 14235',
  taxNumber: 'DE 120 348 279',
  board: ['Dr. Franz-Josef Schulte', 'Simone Ehlen'],
  chairPerson: 'Dr. Stefan Küppers',
  personResponsible: 'Birgit Kirch',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: `http://www.${baseData.domain}/service/impressum`,
    privacyPolicy: `http://www.${baseData.domain}/service/datenschutz`,
    termsAndConditions: `https://www.rww.de/unternehmen/lieferantenportal`,
  },
  theme: {
    colors,
    icons: {
      headerLogo: logo,
      headerLogoWidth: 129,
      headerLogoHeight: 31,
      footerLogo: '',
      footerLogoWidth: 0,
      footerLogoHeight: 0,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '72',
      width: '220',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Vorsitzender des Aufsichtsrates:<br />${
      baseData.chairPerson
    }<br /> Geschäftsführung: ${baseData.board.join(
      ', ',
    )}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${
      baseData.name
    }<br />Sitz ${baseData.address.city} | Amtsgericht ${baseData.districtCourt} | ${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '46px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Vorsitzender des Aufsichtsrates:<br />${
      baseData.chairPerson
    }<br /> Geschäftsführung:<br />${baseData.board.join(', ')}<br /><br />Sitz ${
      baseData.address.city
    }<br />Amtsgericht ${baseData.districtCourt}<br />${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
