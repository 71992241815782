import { CompanyTag } from 'src/application/theming/companyConfigs/types';
import { AuthProvider, type Configuration } from 'src/config/type';

export const qaAppConfig: Configuration = {
  authentication: {
    isEnabled: true,
    defaultProvider: AuthProvider.CIAM,
    providers: [
      {
        name: AuthProvider.CIAM,
        authBaseUrl: 'https://account-qa.${DOMAIN}/services/oauth2/authorize',
        tokenBaseUrl: 'https://account-qa.${DOMAIN}/services/oauth2/token',
        logoutBaseUrl:
          'https://account-qa.${DOMAIN}/secur/logout.jsp?retUrl=/apex/CIAM_SmartExtranetLogout?rls=0',
        clientId:
          '3MVG9od6vNol.eBhEpCYDGdnu3D5TrogCJ_dNYNtZg.KaGDUw5N_LOoS0HpOo.co79Lisnj0iP6fpnvZ5KsW6',
        mfaRegisterUrl: 'https://account-qa.${DOMAIN}/s/2fa-registration?appId=${APP_ID}',
        redirectUrl: 'https://qa-smexnet.${DOMAIN}/oauth/return/default',
        scope: 'openid profile refresh_token',
        refreshTokenLifetimeMilliseconds: 86_100_000,
      },
      {
        name: AuthProvider.AAD,
        authBaseUrl:
          'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/oauth2/v2.0/authorize',
        tokenBaseUrl:
          'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/oauth2/v2.0/token',
        logoutBaseUrl: '/',
        clientId: '1bfe0066-a632-40af-ba25-1a8215dfe2b3',
        redirectUrl: 'https://qa-smexnet.${DOMAIN}/oauth/return/default',
        scope: 'openid profile email User.Read',
        refreshTokenLifetimeMilliseconds: 86_100_000,
      },
    ],
  },
  maintenanceEnabled: false,
  maintenanceExcludedCompanies: [
    CompanyTag.AVA,
    CompanyTag.BAGE,
    CompanyTag.BAGN,
    CompanyTag.EDIS,
    CompanyTag.EENG,
    CompanyTag.EVNG,
    CompanyTag.ENG,
    CompanyTag.REGN,
    CompanyTag.HANG,
    CompanyTag.HAW,
    CompanyTag.LEW,
    CompanyTag.LVN,
    CompanyTag.RWW,
    CompanyTag.SHNG,
    CompanyTag.TNNG,
    CompanyTag.VSE,
    CompanyTag.WCON,
    CompanyTag.WEVG,
    CompanyTag.WN,
  ],
  companyTag: CompanyTag.SHNG,
  smartExtranetApi: {
    apiToken: 'USE_OPEN_ID_CONNECT_ACCESS_TOKEN',
    baseUrl: 'https://smex-api-qas.eon.com/api/${COMPANY_TAG}',
    mockedEndpoints: [
      'getMaterial',
      'getMaterialMovementsPage',
      'getInventoryCompanies',
      'getMaterialStockSettings',
      'getMaterialBatchPage',
      'getInventoryMaterialData',
      'getSmallMaterial',
      'getSmallMaterialDetailTab',
      'getMaterialCatalogMovementsPage',
      'getSmallMaterialRequestData',
      'getOrderAccountAssignmentData',
      'getRequestMaterialOverview',
      'getWaste',
      'getAllOrderJobs',
      'getOrderInvoicingPlannedServices',
      'keyUserAdminToolConfigurationCatalogues',
      'keyUserAdminToolConfigurationCatalogueDocumentCategory',
    ],
  },
  smartExtranetPublicApi: {
    baseUrl: 'https://smex-api-qas.eon.com/public/${COMPANY_TAG}',
    mockedEndpoints: [],
  },
  googleMapsJavascriptApi: {
    authentication: {
      key: 'AIzaSyAAzZznav5yPrcQFBRrxOVj4QdQNSo4z6o',
      v: '3.42',
    },
  },
  azureApplicationInsights: {
    instrumentationKey: 'b10aa8ec-ca6f-43da-9861-e25a2f5d6a05',
  },
  recaptchaSiteKey: '6Lfn1EQfAAAAAEcyZP2VJM9ocLzmvHoPJA25SdqI',
  serviceDeskUrl:
    'https://sigmatest.service-now.com/smart_extranet?id=support_request&sys_id=9585668c1b55d1104af732649b4bcba8',
  sentryLogging: {
    enabled: true,
    dsn: 'https://759f2fb6ad655ee5ff1ea129095d54c0@sentry.eon.com/148',
  },
  newRelicConfiguration: {
    licenseKey: 'NEW_RELIC_LICENSE_KEY',
    applicationId: '538616883',
    accountId: '4490152',
    agentId: '538616883',
    trustKey: '4490152',
  },
};
