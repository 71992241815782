// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-haw.svg';
import colors from './default.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.HAW,
  domain: 'hansewerk.com',
  name: 'HanseWerk AG',
  // TODO: clarify and insert correct data --- START
  contactEmail: 'smexnet@sh-netz.com',
  address: {
    street: 'Lilienthalstraße',
    houseNumber: '7',
    zipCode: '93049',
    city: 'Regensburg',
  },
  districtCourt: 'Regensburg',
  commercialRegisterNumber: 'HRB 9476',
  taxNumber: 'DE 814 365 771',
  board: [],
  chairPerson: 'Gudrun Alt, Dr. Joachim Kabs, Robert Pflügl',
  personResponsible: 'Maximilian Zängl',
  // TODO: clarify and insert correct data --- END
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.hansewerk.com/de/ueber-uns/impressum.html',
    privacyPolicy: 'https://www.hansewerk.com/de/ueber-uns/datenschutz.html',
    termsAndConditions: 'https://www.hansewerk.com/de/ueber-uns/agb.html',
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 90,
      headerLogoHeight: 33,
      // TODO: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 90,
      footerLogoHeight: 33,
      favicon: '/favicons/favicon-haw.ico',
    },
  },
  email: {
    name: 'HanseWerk AG',
    logo: {
      src: 'https://smexnet.sh-netz.com/assets/logo-haw.png',
      alt: 'HanseWerk AG Logo',
      height: '33',
      width: '90',
    },
    imprintAddress: 'HanseWerk AG | Zentrale | Schleswag-HeinGas-Platz 1 | 25451 Quickborn',
    imprint:
      'Vorstand: Matthias Boxberger (Vorsitzender), Christian Fenger, Stephanie Ladwig<br />Vorsitzender des Aufsichtsrates: Harald Heß<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: Ove Struck<br /><br />HanseWerk GmbH<br />Sitz: Quickborn | Amtsgericht Pinneberg | HRB 5802<br />USt-ID-Nr. DE 134852611<br />',
    contactEmail: 'smexnet@sh-netz.com',
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '54px',
    address: 'Schleswag-HeinGas-Platz 1<br />25451 Quickborn<br />www.hansewerk.com',
    imprint:
      'Vorsitzender des<br />Aufsichtsrates:<br />Harald Heß<br /><br />Vorstand:<br /> Matthias Boxberger (Vorsitzender)<br />Christian Fenger<br />Stephanie Ladwig<br /><br />Sitz Quickborn<br />Amtsgericht Pinneberg<br />HRB 5802',
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  moreToolsLinks: {
    materialInvoicing: {
      prod: 'https://exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=pwg.apps.eon.com&TARGET=https%3A%2F%2Fexsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000036',
      default:
        'https://q-exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=qwg.apps.eon.com&TARGET=https%3A%2F%2Fq-exsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000015',
    },
  },
  purchaseOrganisation: '1505',
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
