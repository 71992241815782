import * as Sentry from '@sentry/react';
import { getConfig } from 'src/config/config';
import { getCurrentEnvironment } from 'src/config/environment';

export const initSentryLogging = (): void => {
  const config = getConfig();
  if (!config.sentryLogging || !config.sentryLogging.enabled) {
    return;
  }
  Sentry.init({
    dsn: config.sentryLogging.dsn,
    environment: getCurrentEnvironment(),
    integrations: [Sentry.extraErrorDataIntegration()],
  });
};
