import { AppEnvironment, isAppEnvironment } from './AppEnvironment';

const ENVIRONMENT_HOSTNAME_MAPPING: Array<[hostnamePrefix: string, appEnv: AppEnvironment]> = [
  ['dev-smexnet', AppEnvironment.Dev],
  ['qa-smexnet', AppEnvironment.Qa],
  ['poc-smexnet', AppEnvironment.Poc],
  ['smexnet', AppEnvironment.Prod],
  ['localhost', AppEnvironment.Local],
  ['smexnet-fe-storybook-dev', AppEnvironment.StorybookDev],
  ['smexnet-fe-storybook-qa', AppEnvironment.StorybookQa],
  ['smexnet-fe-storybook-prod', AppEnvironment.StorybookProd],
];

export function getCurrentEnvironment(): AppEnvironment {
  const appEnvironment = tryReadAppEnvironment();

  if (appEnvironment) {
    return appEnvironment;
  }

  const mappedEnv = ENVIRONMENT_HOSTNAME_MAPPING.find(([h]) =>
    window.location.hostname.startsWith(h),
  )?.[1];

  return mappedEnv ?? AppEnvironment.Default;
}

function tryReadAppEnvironment(): AppEnvironment | undefined {
  if (isCypressEnvironment()) {
    return AppEnvironment.Cypress;
  }
  const appEnv = process.env.NODE_ENV;
  return isAppEnvironment(appEnv) ? appEnv : undefined;
}

function isCypressEnvironment(): boolean {
  // @ts-expect-error no-implicit-any (FIXME)
  return window['Cypress'] !== undefined;
}

export function isLocalEnvironment(environment = getCurrentEnvironment()) {
  return [
    AppEnvironment.Local,
    AppEnvironment.StorybookDev,
    AppEnvironment.StorybookQa,
    AppEnvironment.StorybookProd,
    AppEnvironment.Cypress,
  ].includes(environment);
}
