import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-shng.svg';
import colors from './default.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.SHNG,
  domain: 'sh-netz.com',
  name: 'Schleswig-Holstein Netz GmbH',
  contactEmail: 'smexnet@sh-netz.com',
  address: {
    street: 'Schleswag-HeinGas-Platz',
    houseNumber: '1',
    zipCode: '25451',
    city: 'Quickborn',
  },
  districtCourt: 'Pinneberg',
  commercialRegisterNumber: 'HRB 8122 PI',
  taxNumber: 'DE 267 393 355',
  board: ['Steffen Bandelow', 'Malgorzata Cybulska', 'Dr. Benjamin Merkt'],
  chairPerson: 'Matthias Boxberger',
  personResponsible: 'Ove Struck',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.sh-netz.com/de/schleswig-holstein-netz/impressum.html',
    privacyPolicy: 'https://www.sh-netz.com/de/schleswig-holstein-netz/datenschutz.html',
    termsAndConditions: 'https://www.sh-netz.com/de/schleswig-holstein-netz/agb.html',
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 184,
      headerLogoHeight: 33,
      footerLogo: '',
      footerLogoWidth: 184,
      footerLogoHeight: 33,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '33',
      width: '184',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Vorsitzender des Aufsichtsrates:<br />${
      baseData.chairPerson
    }<br /> Vorstand: ${baseData.board.join(', ')}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${
      baseData.personResponsible
    }<br /><br />${baseData.name}<br />Sitz ${baseData.address.city} | Registergericht ${
      baseData.districtCourt
    } | ${baseData.commercialRegisterNumber}<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '54px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Vorsitzender des Aufsichtsrates:<br />${
      baseData.chairPerson
    }<br /> Geschäftsführung:<br />${baseData.board.join(', ')}<br /><br />Sitz ${
      baseData.address.city
    }<br />Amtsgericht ${baseData.districtCourt}<br />${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingSHNG.id,
  },
  moreToolsLinks: {
    materialInvoicing: {
      prod: 'https://exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=pwg.apps.eon.com&TARGET=https%3A%2F%2Fexsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000036',
      default:
        'https://q-exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=qwg.apps.eon.com&TARGET=https%3A%2F%2Fq-exsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000015',
    },
  },
  purchaseOrganisation: '1556',
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
