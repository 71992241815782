import { CompanyTag } from 'src/application/theming/companyConfigs/types';
import { AuthProvider, type Configuration } from 'src/config/type';

export const cypressAppConfig: Configuration = {
  authentication: {
    isEnabled: true,
    defaultProvider: AuthProvider.LOCAL,
    providers: [
      {
        name: AuthProvider.LOCAL,
        authBaseUrl: 'http://localhost:4000/auth/local-provider/login',
        tokenBaseUrl: 'http://localhost:4000/auth/local-provider/token',
        logoutBaseUrl: '/local-logout',
        clientId: '',
        redirectUrl: '',
        scope: '',
      },
    ],
  },
  companyTag: CompanyTag.SHNG,
  smartExtranetApi: {
    apiToken: 'USE_OPEN_ID_CONNECT_ACCESS_TOKEN',
    baseUrl: '',
    mockedEndpoints: [],
  },
  smartExtranetPublicApi: {
    baseUrl: '',
    mockedEndpoints: ['all'],
  },
  googleMapsJavascriptApi: {
    authentication: {
      key: 'AIzaSyAAzZznav5yPrcQFBRrxOVj4QdQNSo4z6o',
      v: '3.42',
    },
  },
  recaptchaSiteKey: '6LedvV8UAAAAAMJ2ZObGwgEwGvN1uLoRfR8JJ7nC',
  serviceDeskUrl:
    'https://sigmatest.service-now.com/smart_extranet?id=support_request&sys_id=9585668c1b55d1104af732649b4bcba8',
  sentryLogging: {
    enabled: true,
    dsn: 'https://759f2fb6ad655ee5ff1ea129095d54c0@sentry.eon.com/148',
  },
};
